import React from "react";
import { Form, Icon, Input, Button, Card } from "antd";
import { reactLocalStorage } from "reactjs-localstorage";
import "../styles/css/roomList.scss";
import { selectMeetingNameList, monitorAdd } from "./api/categories";

class UserLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            login: false,
            loading: false,
            collapsed: false,
            loginInfo: {},
            uid: '',
            roomId: '',
            name: '',
            users: [],
            messages: [],
            localAudioEnabled: true,
            localVideoEnabled: true,
            screenSharingEnabled: false,
            vidFit: false,
            brush: false,
            isFullScreen: false,
            FieldsValue: {
                'roomId': "",
                'displayName': "",
                'displayPassword': ""
            },
            MeetingList: [],
        };
    }

    componentDidMount() {
        this.getSelectMeetingNameList();
        this.showNavigator();
    }

    showNavigator() {
        const constraints = {
            audio: true,
            video: true
        };
        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                console.log("请求成功");
            })
            .catch(error => {
                console.log("请求失败", error);
            });
    }

    getSelectMeetingNameList = async () => {
        try {
            const res = await selectMeetingNameList({
                userId: sessionStorage.getItem('userId') || '25'
            });
            if (res.code === "200") {
                this.setState({
                    MeetingList: res.data
                });
            } else {
                alert("查询失败");
            }
        } catch (error) {
            console.error("获取会议列表失败", error);
        }
    }

    ToMain = async (msg, roomName) => {
        try {
            const res = await monitorAdd({
                userId: sessionStorage.getItem('userId') || '25',
                userName: sessionStorage.getItem('userName') || "hw1",
                roomId: msg || "80001"
            });
            if (res.code === "200") {
                sessionStorage.setItem("monitorId", res.data);
                sessionStorage.setItem("toMeetingTime", new Date().getTime());
                sessionStorage.setItem('roomId', msg);
                sessionStorage.setItem('roomName', roomName);
                this.props.history.push("/Samples");
            } else {
                alert("查询失败");
            }
        } catch (error) {
            console.error("进入会议失败", error);
        }
    }

    handleSubmit = e => {
        e.preventDefault();
    };

    render() {
        return (
			<div className="div-main">
			  <div className="div-show1">医院列表</div>
			  <div className="container">
			    <div className="div-show3">
			      {this.state.MeetingList.map(item => (
			        <div className="div-show" key={item.id} id={item.id} onClick={() => this.ToMain(item.id, item.meetingName)}>
			          
			          {item.meetingName}
			        </div>
			      ))}
			    </div>
			  </div>
			</div>
        );
    }
}

export default UserLogin;