// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../image/bg.jpeg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".login-bg{display:flex;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:top;background-size:100% auto;background-attachment:fixed;justify-content:center;align-items:center;padding:20px}.login-title{text-align:center;padding:20px 0;font-size:24px;color:#323232;letter-spacing:1px;font-family:\"微软雅黑\"}.login-form{width:100%;max-width:360px;background-color:#fff;border:1px solid #f9f9f9;border-radius:4px;padding:20px}.login-input-icon{color:rgba(0,0,0,.25)}.login-join-button{width:100%}@media(max-width: 768px){.login-title{font-size:20px}.login-form{padding:15px}}@media(max-width: 480px){.login-title{font-size:18px}.login-form{width:100%;padding:10px}.login-input-icon{font-size:14px}.login-join-button{font-size:14px}}", ""]);
// Exports
module.exports = exports;
